<template>
  <div class="container-fluid pb-5">
    <div class="row">
      <div class="col-12 pl-2 pr-4 pt-4 bg-accent">
        <div class="row px-2">
          <div class="col-lg-8">
            <div class="toolbar mt-2">
              <div class="btn-groupn" role="group" aria-label="Basic example">
                <h3 class="vv">Settings</h3>
              </div>
            </div>
          </div>
          <div class="col text-right">
            <button
              type="button"
              class="btn btn-mid btn-primary d-inline"
              @click="verify()"
            >
              <span class="align-middle">Update Settings</span>
              <span
                v-show="saving"
                class="loading pl-1 my-auto pt-1 pull-right text-right"
              ></span>
            </button>
            
          </div>
        </div>
      </div>

      <div class="col-12 bg-accent border-bottom" v-show="ready">
        <ul class="nav nav-tabs px-0 mx-0" id="myTab" role="tablist">
          <li class="nav-item">
            <a
              class="nav-link active"
              id="home-tab"
              data-toggle="tab"
              href="#home"
              role="tab"
              >Payment</a
            >
          </li>
          <li class="nav-item">
            <router-link
              class="nav-link"
              id="billing-tab"
              
              :to="'/d/billing'"
              role="tab"
              >Billing</router-link
            >
          </li>
        </ul>
      </div>
      <div class="col-12" v-show="ready">
        <div class="tab-content" id="myTabContent">
          <div
            class="tab-pane fade show active px-2 pt-4"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div class="row">
              <div class="col-lg-8">
                <div class="row">
                  <div class="form-group col-12">
                    <label> Paypal Email Address</label>
                    <input
                      v-model="settings.paypal_email"
                      type="email"
                      class="form-control"
                    />
                  </div>
                  
                </div>
              </div>
              
            </div>
          </div>
          
        </div>
      </div>
    </div>
  </div>
</template>

<style>
@import "../assets/css/ql.css";
</style>

<script>
import Snackbar from "node-snackbar";
import "node-snackbar/dist/snackbar.css";
import { VueEditor } from "vue2-editor";
export default {
  name: "LicencesNew",
  components: {
    VueEditor,
  },
  data() {
    return {
      ready: true,
      saving: false,
      validationErrors: [],
      settings: {
        paypal_email: "",
        
      },
    };
  },
  mounted() {
    this.ready = false;

    this.get_settings();

    //$('#file_modal').modal('show')
  },
  methods: {
    get_settings() {
      var baseURI = this.$baseurl + "/settings";
      var config = {
        Authorization: "Bearer " + this.$store.getters.getUser.token,
      };

      this.$http
        .get(baseURI, {
          headers: config,
        })
        .then((result) => {
          if (result.data.message === "invalid token") {
          } else {
          }
          if (result.data) {
            this.settings = result.data;
          }

          //this.keys = Object.keys(this.res[0]);
        });
      this.ready = true;
    },
    cancel() {
      this.$router.push("/d/overview");
    },
    verify() {
      var send = this.settings;
      
      var baseURI,
        msg = "";
      baseURI = this.$baseurl + "/settings";
      
      var config = {
        Authorization: "Bearer " + this.$store.getters.getUser.token,
      };
      
        this.$http
          .post(baseURI, send, {
            headers: config,
          })
          .then((result) => {
            this.process(result, msg);
            //this.keys = Object.keys(this.res[0]);
          });
      
    },
    process(result, msg) {
      if (result.data.error) {
        this.validationErrors.push({
          message: result.data.message,
        });
        Snackbar.show({
          text: result.data.message,
          customClass: "snack-danger",
          actionText: "DISMISS",
        });
        this.saving = false;
      } else {
        Snackbar.show({
          text: "settings updated",  
          customClass: "snack-success",
          actionText: "OK",
        });
        this.saving = false;
        this.$router.push("/d/overview");
      }
    },
  },
};
</script>

<style>
</style>